<template>
  <v-container grid-list-lg class="pr-0">
    <event-general
      display-mode
      :items="event"
      hide-submit />

    <event-params
      display-mode
      hide-submit
      :items="event.calculateParams">
      <display-field
        v-if="event.minParcelValue"
        label="Parcela minima"
        :value="event.minParcelValue ? $n(event.minParcelValue, 'currency') : ''" />
      <display-field v-else label="Parcela minima" />
      <display-field label="Email para dispensa" :value="event.allowanceEmail" />
      <display-field label="Juros pró-rata" :value="event.calculateParams.interestedFullMonth ? 'Sim' : 'Não'" />
      <display-field label="Valor das despesas na dívida" :value="event.calculateParams.passExpenses ? 'Sim' : 'Não'" />
      <display-field label="Titulos para dispensa" :value="event.calculateParams.enableIgnoreTicketsOnInitialProposal ? 'Sim' : 'Não'" />
    </event-params>

    <v-container grid-list-lg class="pa-0">
      <h2 class="subtitle-1 mb-2">
        Detalhes dos Canais
      </h2>

      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <display-field label="E-mail" :value="event.email.send ? 'Sim' : 'Não'" />
            <display-field label="Sms" :value="event.sms.send ? 'Sim' : 'Não'" />
            <display-field label="Carta" :value="event.letter.send ? 'Sim' : 'Não'" />
          </tbody>
        </template>
      </v-simple-table>
    </v-container>

    <v-container grid-list-lg class="pa-0">
      <h2 class="subtitle-1 mb-2">
        Detalhes dos fluxos
      </h2>

      <v-simple-table class="display-field" dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="table-text text-left font-weight-bold">
                Proposta inicial
                <info-tooltip text="É a negociação inteligente, entre a plataforma e o convidado, para a solução rápida, eficiente e satisfatória para todos os envolvidos." direction="right" />
              </td>
              <td class="text-right">
                <v-btn icon x-small :to="{ name: 'EventInitialProposal' }">
                  <v-icon>keyboard_arrow_right</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <td class="table-text text-left font-weight-bold">
                Negociação Online
                <info-tooltip text="É a negociação direta e online entre um Negociador selecionado e o convidado, para que juntos possam criar alternativas para uma solução definitiva e satisfatória." direction="right" />
              </td>
              <td class="text-right">
                <v-btn icon x-small :to="{ name: 'EventNegotiationOnline' }">
                  <v-icon>keyboard_arrow_right</v-icon>
                </v-btn>
              </td>
            </tr>
            <!-- <tr>
              <td class="table-text text-left font-weight-bold">
                Negociação Presencial
                <info-tooltip text="É a negociação entre um Negociador selecionado e o convidado, para que juntos possam criar alternativas para uma solução definitiva e satisfatória." direction="right" />
              </td>
              <td class="text-right">
                <v-btn icon x-small :to="{ name: 'EventNegotiationFaceToFace' }">
                  <v-icon>keyboard_arrow_right</v-icon>
                </v-btn>
              </td>
            </tr> -->
            <tr>
              <td class="table-text text-left font-weight-bold">
                Mediação Online
                <info-tooltip text="É o método conduzido por um facilitador, que atua de forma imparcial e online, podendo ser voluntário ou profissional, para alcançar uma solução definitiva e satisfatória." direction="right" />
              </td>
              <td class="text-right">
                <v-btn icon x-small :to="{ name: 'EventMediationOnline' }">
                  <v-icon>keyboard_arrow_right</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>

    <v-container grid-list-lg class="pa-0">
      <config-pay
        class="px-0"
        display-mode
        :items="event.payment"
        hide-submit />
    </v-container>
  </v-container>
</template>

<script>
  const EventGeneral = () => import('@/components/ui/events/EventGeneral')
  const EventParams = () => import('@/components/ui/events/EventParams')
  const DisplayField = () => import('@/components/ui/DisplayField')
  const ConfigPay = () => import('@/components/ui/ConfigPay')
  const InfoTooltip = () => import('@/components/ui/InfoTooltip')
  import eventsMixin from '@/mixins/events/events'

  export default {
    name: 'EventInfo',
    components: { DisplayField, EventGeneral, EventParams, ConfigPay, InfoTooltip },
    mixins: [ eventsMixin ]
  }
</script>
