<template>
  <v-container grid-list-lg>
    <v-layout
      row
      wrap>
      <v-flex
        sm12>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <display-field label="Expirar em" suffix="dias" :value="proposal.expirationDays" />
              <display-checked label="Contra-proposta" :status="proposal.counterProposal" table />
            </tbody>
          </template>
        </v-simple-table>

        <event-parcel-rules
          v-if="['local'].includes(event.calculate || 'local')"
          :items="proposal.installmentRule"
          :display-mode="true"
          :calculate-params="event.calculateParams" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  const EventParcelRules = () => import('@/components/ui/events/EventParcelRules')
  const DisplayChecked = () => import('@/components/ui/DisplayChecked')
  import eventsMixin from '@/mixins/events/events'
  import eventsUiMixin from '@/mixins/events/events-ui'

  export default {
    name: 'EventInfo',
    components: { DisplayChecked, EventParcelRules },
    mixins: [ eventsMixin, eventsUiMixin ],
    computed: {
      proposal () {
        return this.event.proposal
      }
    }
  }
</script>
