import Loading from '@/components/ui/Loading'
import { mapGetters } from 'vuex'

export default {
  components: { Loading },
  methods: {
    _failureDataEvent () {
      this.$root.$emit('snackbar', {show: true, color: 'error', text: 'Campanha não encontrada'})
      this.$router.push({ name: 'Events' })
    }
  },
  computed: {
    ...mapGetters({
      event: 'events/record',
      loading: 'events/recordLoading'
    })
  }
}
