<template>
  <v-container grid-list-lg>
    <v-layout
      row
      wrap>
      <v-flex
        sm12>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <display-field label="Expirar em" suffix="dias" :value="mediation.expirationDays" />
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  const DisplayChecked = () => import('@/components/ui/DisplayChecked')
  import eventsMixin from '@/mixins/events/events'
  import eventsUiMixin from '@/mixins/events/events-ui'

  export default {
    name: 'NegotiationOnline',
    components: { DisplayChecked },
    mixins: [ eventsMixin, eventsUiMixin ],
    computed: {
      mediation () {
        return this.event.mediators
      }
    }
  }
</script>
