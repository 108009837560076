<template>
  <side-content
    modal
    :is-subcontext="$route.meta.subcontext || false"
    :title="$route.meta.title"
    @back="sideContentBack"
    @close="sideContentClose">
    <loading
      v-if="loading > 0"
      class="absolute-center" />
    <router-view v-else />
  </side-content>
</template>

<script>
  import eventsMixin from '@/mixins/events/events'

  export default {
    name: 'ShowEvent',
    mixins: [ eventsMixin ],
    watch: {
      '$route.params.eventId' () {
        this.getRecord()
      }
    },
    beforeMount () {
      this.getRecord()
    },
    methods: {
      sideContentClose () {
        this.$router.push({ name: 'Events' })
      },
      sideContentBack () {
        this.$router.push({ name: 'EventInfo', params: { eventId: this.$route.params.eventId } })
      },
      getRecord () {
        this.$store.dispatch('events/get', this.$route.params.eventId)
      }
    }
  }
</script>
